<template>
  <div class="home">
    <h2>This site is under construction.  Check back often for updates.</h2>
    <div class="picbox">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

</script>
<style scoped>
.picbox {
  background-size:cover;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-image: url("@/assets/cityLong1.png");
  aspect-ratio: 5/2;
}
</style>