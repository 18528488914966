<template>
  <div>
    <div class="banner">
    </div>
    <div class="toprow">
      <img src="@/assets/constr1.png">
      <h1>US-EG Consulting</h1>
      <h2>-- Local business optimization for success in a global economy.</h2>
    </div>
    <router-view/>
    <footer id="footer">
      <FootHeader/>
    </footer>
    <div class="banner">
    </div>
  </div>
</template>

<script>
import FootHeader from '@/components/FootHeader.vue'

export default {
  components: {
    FootHeader
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #79b3ec;
}
.banner {
  background-size:contain;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-image: url("@/assets/banner1.png");
  min-height: 40px;

}
.toprow{
  display: flex;
  align-items: center;
  text-align: left;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  justify-content: space-around;
}
.toprow img {
  display: flex;
  width: 87px;
  height:87px;
}
.toprow h1 {
  display: flex;
  font-size:40px;
  padding:10px 30px;
}
nav {
  padding: 30px;
}

#footer{
  color: black;
  padding: 10px, 10px;
  background: #79b3ec;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}
</style>
