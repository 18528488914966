<template>
    <div>
        <p>Copyright © 2024 ---- US-EG Consulting</p>
        <p>For more information, feel free to reach out to us at <b>us.eg.consulting@gmail.com</b></p>
    </div>
</template>

<style scoped>

</style>
